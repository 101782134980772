import React from "react";
import { PopupButton } from "@typeform/embed-react";

function OtherPositions() {
  return (
    <section class="other-position">
      <div class="container">
        <h1 class="other-position__title">Non tech jobs</h1>
        <p class="section__desc">Check other jobs then tech</p>
        <div class="other-position__content">
          <div class="position">
            <p class="position__job">Web marketing</p>
            <PopupButton
              id="WZH0AGYq"
              size={75}
            //   data-tf-iframe-props="title=Join our team"
            //   data-tf-medium="snippet"
              className="btn btn-primary btn-sm custom-button"
            //   href="job-details.html"
            >
              Apply Now
              <img
                alt="arrow icon"
                class="position__icon"
                src="assets/img/icons/icn_arrow.svg"
              />
            </PopupButton>
          </div>
          <div class="position">
            <p class="position__job">Accounting</p>
            <PopupButton
              id="WZH0AGYq"
              size={75}
            //   data-tf-iframe-props="title=Join our team"
            //   data-tf-medium="snippet"
              className="btn btn-primary btn-sm custom-button"
            //   href="job-details.html"
            >
              Apply Now
              <img
                alt="arrow icon"
                class="position__icon"
                src="assets/img/icons/icn_arrow.svg"
              />
            </PopupButton>
          </div>
          <div class="position">
            <p class="position__job">Client Experience</p>
            <PopupButton
              id="WZH0AGYq"
              size={75}
            //   data-tf-iframe-props="title=Join our team"
            //   data-tf-medium="snippet"
              className="btn btn-primary btn-sm custom-button"
            //   href="job-details.html"
            >
              Apply Now
              <img
                alt="arrow icon"
                class="position__icon"
                src="assets/img/icons/icn_arrow.svg"
              />
            </PopupButton>
          </div>
        </div>
      </div>
    </section>
  );
}

export default OtherPositions;
