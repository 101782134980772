/* eslint-disable jsx-a11y/heading-has-content */
import React, { useEffect, useState } from "react";
import config from "./helpers/config";
import { PopupButton } from "@typeform/embed-react";
import { redirect } from "react-router-dom";
function Talents({ data, talentsData, pageNum }) {
  const [talents, setTalents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showMore, setShowMore] = useState(null);

  useEffect(() => {
    const url = `${config.host}/api/candidates/paginate?current=${pageNum}`;

    const getTalents = (url) => {
      fetch(url)
        .then((response) => response.json())
        .then((resp) => {
          const data = resp.data;
          setTalents(data);
          setLoading(false);
          talentsData(resp);
        })
        .catch((err) => {
          return redirect("/500");
        });
    };

    getTalents(url);
  }, [pageNum, talentsData]);
  useEffect(() => {
    setTalents(data);
  }, [data]);

  return (
    <section class="talents" id="section-class">
      <div class="container" id="result-container">
        <div class="talents__inner">
          <div class="row" id="talent-container">
            {!loading && talents ? (
              talents.map((talent, index) => (
                <div class="col-lg-10 offset-lg-1 col-xl-10 offset-xl-1 col-xxl-8 offset-xxl-2">
                  <div class="talent">
                    <div class="row">
                      <div class="col-md-3 col-lg-3">
                        <div class="talent__img-wrapper">
                          <img
                            src={`${talent.picture}`}
                            srcset={`${talent.picture}`}
                            class="talent__img"
                            alt="talent"
                          />
                        </div>

                        <PopupButton
                          id="dSlRsHsN"
                          size={75}
                          data-tf-iframe-props="title=Hire talent"
                          data-tf-medium="snippet"
                          data-tf-auto-close
                          className="btn btn-primary btn-sm"
                        >
                          {` Hire ${talent.firstname} `}
                        </PopupButton>
                      </div>
                      <div class="col-md-9 col-lg-9">
                        <div class="talent__content">
                          <h5 class="secondary-text">
                            {`    ${talent.firstname} ${talent.lastname} `}
                          </h5>
                          <p class="talent__job-title">{`${talent.job_title}`}</p>
                          <p class="talent__location">
                            {`    ${talent.city}, ${talent.country} `}
                          </p>

                          <div class="custom-collapse">
                            <p
                              class={`talent__condidate-desc ${
                                showMore === index ? "" : "limit-text"
                              }`}
                            >
                              {`   ${talent.bio} `}
                            </p>
                          </div>
                          <button
                            class="talent__show-more"
                            text-decoration-none
                            onClick={() =>
                              setShowMore((prev) =>
                                prev === index ? null : index
                              )
                            }
                          >
                            {` ${
                              showMore === index ? "show less" : "show more"
                            }`}
                          </button>
                          <div class="talent__skills">
                            {talent.skills.map((skill) => (
                              <span class="talent__skill">{`${skill}`}</span>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div class="row">
                <div class="col-lg-10 offset-lg-1 col-xl-10 offset-xl-1 col-xxl-8 offset-xxl-2">
                  <div class="talent-shimmer talent">
                    <div class="row">
                      <div class="col-md-3 col-lg-3">
                        <div class="talent-shimmer__img talent__img-wrapper"></div>
                        <div class="talent-shimmer__btn"></div>
                      </div>
                      <div class="col-md-9 col-lg-9">
                        <div class="talent__content">
                          <h5 class="talent-shimmer__name"></h5>
                          <p class="talent-shimmer__job-title"></p>
                          <p class="talent-shimmer__location"></p>

                          <div class="talent-shimmer__collapse custom-collapse">
                            <p class="talent-shimmer__condidate-desc"></p>
                            <p class="talent-shimmer__condidate-desc"></p>
                            <p class="talent-shimmer__condidate-desc"></p>
                          </div>

                          <div class="talent__skills">
                            <span class="talent-shimmer__skill"></span>
                            <span class="talent-shimmer__skill"></span>
                            <span class="talent-shimmer__skill"></span>
                            <span class="talent-shimmer__skill"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-10 offset-lg-1 col-xl-10 offset-xl-1 col-xxl-8 offset-xxl-2">
                  <div class="talent-shimmer talent">
                    <div class="row">
                      <div class="col-md-3 col-lg-3">
                        <div class="talent-shimmer__img talent__img-wrapper"></div>
                        <div class="talent-shimmer__btn"></div>
                      </div>
                      <div class="col-md-9 col-lg-9">
                        <div class="talent__content">
                          <h5 class="talent-shimmer__name"></h5>
                          <p class="talent-shimmer__job-title"></p>
                          <p class="talent-shimmer__location"></p>

                          <div class="talent-shimmer__collapse custom-collapse">
                            <p class="talent-shimmer__condidate-desc"></p>
                            <p class="talent-shimmer__condidate-desc"></p>
                            <p class="talent-shimmer__condidate-desc"></p>
                          </div>

                          <div class="talent__skills">
                            <span class="talent-shimmer__skill"></span>
                            <span class="talent-shimmer__skill"></span>
                            <span class="talent-shimmer__skill"></span>
                            <span class="talent-shimmer__skill"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-10 offset-lg-1 col-xl-10 offset-xl-1 col-xxl-8 offset-xxl-2">
                  <div class="talent-shimmer talent">
                    <div class="row">
                      <div class="col-md-3 col-lg-3">
                        <div class="talent-shimmer__img talent__img-wrapper"></div>
                        <div class="talent-shimmer__btn"></div>
                      </div>
                      <div class="col-md-9 col-lg-9">
                        <div class="talent__content">
                          <h5 class="talent-shimmer__name"></h5>
                          <p class="talent-shimmer__job-title"></p>
                          <p class="talent-shimmer__location"></p>

                          <div class="talent-shimmer__collapse custom-collapse">
                            <p class="talent-shimmer__condidate-desc"></p>
                            <p class="talent-shimmer__condidate-desc"></p>
                            <p class="talent-shimmer__condidate-desc"></p>
                          </div>

                          <div class="talent__skills">
                            <span class="talent-shimmer__skill"></span>
                            <span class="talent-shimmer__skill"></span>
                            <span class="talent-shimmer__skill"></span>
                            <span class="talent-shimmer__skill"></span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Talents;
