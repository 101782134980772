import React from "react";
import { PopupButton } from "@typeform/embed-react";
import { Link } from "react-router-dom";
function Hero() {
  return (
    <>
      <section class="hero">
        <div class="container">
          <div class="hero__content">
            <div class="row align-items-center">
              <div class="col-12 col-lg-7">
                <div class="hero__description">
                  <h1 class="hero__title">
                    We connect top 1% African remote talents with European tech
                    teams.
                  </h1>
                  <p class="hero__text">
                    We augment your great team with top tier remote developers,
                    designers and data scientists. Alternatively we build
                    dedicated teams in house to bring your next project to
                    success.
                  </p>
                  <div>
                    <div class="hero__btn-cta">
                      <PopupButton
                        id="dSlRsHsN"
                        data-tf-iframe-props="title=Hire talent"
                        data-tf-medium="snippet"
                        data-tf-auto-close
                        size={75}
                        className="btn btn-primary"
                      >
                        Hire remote talents
                      </PopupButton>
                      <Link to="/join-us" style={{ textDecoration: "none" }}>
                        <a
                          href="join-us.html"
                          class="btn btn-outline-light btn-outline-light--custom hero-join-us-btn"
                        >
                          Apply for jobs
                        </a>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12 col-lg-5">
                <div class="hero__img-wrapper">
                  <img
                    class="hero__img"
                    src="assets/img/img_hero.png"
                    srcset="assets/img/img_hero@2x.png 2x"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="trusted-companies">
            <p class="trusted-companies__title primary-text">
              Trusted by great companies
            </p>
            <div class="trusted-companies__list">
              <img alt="logo sanofi" src="assets/img/icons/logo_sanofi.svg" />
              <img alt="logo whitebird" src="assets/img/icons/logo_whitebird.svg" />
              <img alt="logo temtemone" src="assets/img/icons/logo_temtemone.svg" />
              <img alt="logo capcar" src="assets/img/icons/logo_capcar.svg" />
              <img alt="logo bnp" src="assets/img/icons/logo_bnp.svg" />
              <img alt="logo fansports" src="assets/img/icons/logo_fansports.svg" />
              <img alt="logo peers" src="assets/img/icons/logo_peers.svg" />
            </div>
          </div>
        </div>
      </section>
      <div
        class="modal fade"
        id="step3"
        tabindex="-1"
        aria-labelledby="step3Label"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-dialog-centered modal-lg">
          <div class="modal-content modal-custom/">
            <div class="modal-header modal-header--custom">
              <div
                class="close-icon-modal"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <img class="" src="./assets/img/icons/icn_close.svg" alt="" />
              </div>
              <p class="modal-custom__title">Thank you !</p>
              <p class="modal-custom__desc">We will reach out to you soon !</p>
            </div>
            <div class="modal-body modal-body--custom">
              <p class="modal-custom__steps">Next steps</p>
              <div class="modal-steps">
                <div class="modal-step">
                  <div class="modal-step__img-wrapper">
                    <img
                      class="modal-step__img"
                      src="./assets/img/icons/icn__needs.svg"
                      alt=""
                    />
                  </div>
                  <p class="modal-step__text">Discuss your needs</p>
                </div>
                <img
                  class="modal-steps__icon"
                  src="./assets/img/icons/icn_angle-left.svg"
                  alt=""
                />
                <div class="modal-step">
                  <div class="modal-step__img-wrapper">
                    <img
                      class="modal-step__img"
                      src="./assets/img/icons/icn__proposal.svg"
                      alt=""
                    />
                  </div>
                  <p class="modal-step__text">Talent proposal</p>
                </div>
                <img
                  class="modal-steps__icon"
                  src="./assets/img/icons/icn_angle-left.svg"
                  alt=""
                />
                <div class="modal-step">
                  <div class="modal-step__img-wrapper">
                    <img
                      class="modal-step__img"
                      src="./assets/img/icons/icn__start-working.svg"
                      alt=""
                    />
                  </div>
                  <p class="modal-step__text">Start working!</p>
                </div>
              </div>
            </div>
            <div class="modal-footer modal-footer--custom">
              <button
                type="button"
                class="btn btn-primary modal-custom-button"
                data-bs-dismiss="modal"
                data-bs-target="#step3"
              >
                Ok !
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Hero;
